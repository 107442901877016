<template>
  <CSidebar
    :show="show"
    colorScheme="light"
    overlaid
    size="lg"
    class="c-sidebar-right"
  >
    <CSidebarClose @click.native="$store.commit('toggle', 'cartShow')" />
    <CTabs
      v-if="cart.id && show"
      tabs
      class="nav-underline nav-underline-primary"
    >
      <CTab>
        <template slot="title">
          <h4>{{ $t("cartside.cart.title") }}</h4>
        </template>

        <CButton
          color="info"
          block
          @click="
            $router.push('/shop/M' + $store.state.shop.market.id + '/cart');
            $store.commit('toggle', 'cartShow');
          "
          ><b>{{ $t("cartside.cart.details") }}</b></CButton
        >
        <CButton
          size="lg"
          color="success"
          block
          @click="
            $router.push('/newrole');
            $store.commit('toggle', 'cartShow');
          "
          ><b>{{ $t("cartside.cart.checkout") }}</b></CButton
        >
        <CListGroup>
          <CListGroupItem
            class="text-center font-weight-bold text-muted text-uppercase small"
          >
            {{ $t("cartside.cart.totals") }}:
            <h5>
              <b>{{ cart.tot.toFixed(2) }} CHF</b>
            </h5>
          </CListGroupItem>
          <div v-if="items_loading" class="loading text-center">
            <CSpinner color="secondary" size="lg" />
          </div>
          <MarketCartLine
            v-for="(item, key) in items"
            :key="key"
            v-model="items[key]"
            :size_sm="true"
            v-on:delete-item="deleteItem(key)"
          />
          <!-- <CListGroupItem
            class="list-group-item-accent-secondary list-group-item-divider"
            v-for="(ci, key) in items"
            :key="key"
          >
            <CRow>
              <CCol md=3>
              <img
                class="img-fluid"
                :src="ci.ppm.lot.productproducer.image_cropped"
              />
              </CCol>
              <CCol md=9>
                <strong>{{ci.ppm.lot.productproducer.translations.it.name}}</strong>
                <br>
              {{ci.ppm.lot.productproducer.producer.role.name}}
              <br>
              <small>{{ci.ppm.lot.translations.it.name}}</small>
              </CCol>
              <CCol md=12 class="text-right">
                <small>{{ci.qta}} x {{ci.ppm.price}} CHF = </small><b>{{ (ci.qta*ci.ppm.price).toFixed(2)}} CHF</b>
              </CCol>
            </CRow>
            
          </CListGroupItem> -->
        </CListGroup>
      </CTab>
    </CTabs>
  </CSidebar>
</template>

<script>
import { GetDataManager, ApiCall } from "../ds/index";
import { Query } from "@syncfusion/ej2-data";
import MarketCartLine from "../views/user/MarketCartLine";

// const dm = new DataManager({
//   url: LOONITY_BASE_API_URL + "/api/auth/roles/",
//   adaptor: new DrfAdaptor(),
//   crossDomain: true,
// });
export default {
  name: "TheAside",
  data() {
    return {
      // cart: this.$store.state.cart,
      items_loading: false,
      items: [],
      // items_recent: null,
      // items_lasts: [],
      // search_itmes: [],
      // search_term: "",
      // search_searching: false,
    };
  },
  components: {
    MarketCartLine,
  },
  // created() {
  //   // Fetch recent
  //   // this.GetRecents();
  //   // Fetch last
  //   this.LoadCart(this.$auth.user().cart_id);
  //   // Debounce Search
  //   // this.debouncedFilterRoles = _.debounce(this.FilterRoles, 500);
  // },
  watch: {
    // whenever question changes, this function will run
    show: function (newTerm, oldTerm) {
      // this.answer = 'Waiting for you to stop typing...'
      if (newTerm == true && oldTerm == false) {
        // console.log("Update cart items")
        this.LoadCartDetails();
      }
    },
  },
  computed: {
    cart() {
      return this.$store.state.shop.market_cart;
    },
    show() {
      return this.$store.state.cartShow;
    },
    // items() {
    //   if (this.items_lasts.length > 0 || this.items_recent.length > 0) {
    //     // console.log(this.items_recent);
    //     // console.log(this.items_lasts)
    //     let i_items_recent = this.items_recent.reduce(function (map, obj) {
    //       map.push(obj.id);
    //       return map;
    //     }, []);
    //     let f_items_lasts = this.items_lasts.filter((obj) => {
    //       return !i_items_recent.includes(obj.id);
    //     });
    //     return this.items_recent.concat(f_items_lasts);
    //   }
    //   return [];
    // },
    // user() {
    //   return this.$auth.user() || {};
    // },
  },
  methods: {
    deleteItem: function (index) {
      this.items.splice(index, 1);
    },
    // GetRecents() {
    //   let role_history = localStorage.getItem("role_history");
    //   if (role_history) {
    //     role_history = JSON.parse(role_history);
    //     // Build filter
    //     let predicate = new Predicate("id", "lessthan", 0);
    //     role_history.forEach(function (entry) {
    //       predicate = predicate.or("id", "equal", entry);
    //     });
    //     // get results
    //     let tmp_result = [];
    //     dm.executeQuery(new Query().where(predicate)).then((e) => {
    //       tmp_result = e.result;
    //       let tmp_result_o = [];
    //       role_history.forEach(function (entry) {
    //         let tmp_result_res = tmp_result.filter((obj) => {
    //           return obj.id == entry;
    //         })[0];

    //         if (tmp_result_res) {
    //           tmp_result_o.push(tmp_result_res);
    //         }
    //       });
    //       // console.log("TMP",tmp_result_o);
    //       this.items_recent = tmp_result_o;
    //     });
    //   } else {
    //     this.items_recent = [];
    //   }
    // },
    LoadCartDetails() {
      let self = this;
      self.items_loading = true;
      self.items = [];
      ApiCall(
        // GetDataManager("role_producer_products", [this.$store.state.role.id]),
        GetDataManager("cart_usercarts_detail", [self.cart.id]),
        new Query().take(8).sortBy("ts_modified", "descending"),
        function (e) {
          // self.numPages = Math.ceil(e.actual.Count / 12);
          // self.$store.cart.set(e.result);
          // self.$store.dispatch("cart/add", e.result);
          // self.$store.commit("shop/setCart", e.result)
          // console.log(e.result)
          self.items_loading = false;
          self.items = e.result;
        }
      );
    },
    // FilterRoles() {
    //   if (this.search_term) {
    //     this.search_searching = true;
    //     dm.executeQuery(
    //       new Query()
    //         .search(this.search_term, ["name", "id"], null, true)
    //         .take(10)
    //     ).then((e) => {
    //       this.search_searching = false;
    //       this.search_itmes = e.result;
    //     });
    //   }
    // },
    // GoToRole(role) {
    //   this.$store.commit("setRole", role);
    //   // console.log(this.$store.state.role.name)
    //   //save local history
    //   let role_history = localStorage.getItem("role_history");
    //   // console.log(role_history)
    //   if (!role_history) {
    //     role_history = [];
    //   } else {
    //     role_history = JSON.parse(role_history);
    //   }
    //   role_history = role_history.filter(function (value, index, arr) {
    //     return value != role.id;
    //   });
    //   role_history.unshift(role.id);
    //   console.log(role_history);
    //   localStorage.setItem(
    //     "role_history",
    //     JSON.stringify(role_history.slice(0, 10))
    //   );
    //   this.items_recent = this.items_recent.filter(function (obj, index, arr) {
    //     return obj.id != role.id;
    //   });
    //   this.items_recent.unshift(role);
    //   this.items_recent = this.items_recent.slice(0, 10);

    //   this.search_term = "";
    //   this.search_itmes = [];
    //   this.$router.push({ path: `/role/${role.id}` }).catch((err) => {});
    //   this.$store.commit("set", ["cartShow", false]);
    // },
    // roles () {
    //   roles = new DataManager({
    //     url: LOONITY_BASE_API_URL+"/api/auth/family/",
    //     adaptor: new DrfAdaptor(),
    //     crossDomain: true,
    //   }).fecth()
    //   return roles
    // },
  },
};
</script>
<style src="spinkit/spinkit.min.css"></style>
<style scoped>
div.sk-wave {
  margin: auto;
}
</style>
